import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationDetailsSupportingDocumentationModel } from '@brokerportal/common/models/application';
import {
    InstallationType,
    QuoteDocumentUploadTypes,
    QuoteFormModel,
    QuoteUploadedDocumentModel,
    RequiredDocumentModel,
    Scheme
} from '../../models';
import { ErrorCallbackGeneratorService, QuoteService, QuoteStatusService } from '../../services';
import { TemplateText } from '../../template-text';

@Component({
    selector: 'quote-documents',
    templateUrl: './quote-documents.component.html',
    styleUrls: ['./quote-documents.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteDocumentsComponent implements OnInit {
    @Input()
    quoteFormModel: QuoteFormModel;

    @Input()
    quoteExternalId: string;

    @Input()
    quoteStatus: string;

    @Output()
    onDocumentAvailabilityUpdated = new EventEmitter<boolean>();
    quoteDocumentUploadTypes = QuoteDocumentUploadTypes;

    requiredDocuments: Record<string, RequiredDocumentModel[]> = {
        SA_SUBSIDY: [
            {
                type: QuoteDocumentUploadTypes.SEG,
                callToAction: 'Please upload a copy of Small Embedded Generation (SEG) approval for this installation',
                additionalRequirements: [],
                useMultiUpload: false
            },
            {
                type: QuoteDocumentUploadTypes.eCOC,
                callToAction:
                    'Please upload a copy of the Electronic Certificate of Compliance (eCoC) you have provided to the customer',
                additionalRequirements: [],
                useMultiUpload: false
            },
            {
                type: QuoteDocumentUploadTypes.Bill,
                callToAction:
                    "Please upload a copy of the customer's electricity bill, dated within the past six months",
                additionalRequirements: [],
                useMultiUpload: false
            },
            {
                type: QuoteDocumentUploadTypes.SignedQuote,
                callToAction: 'Please upload a copy of the quote you have provided to the customer',
                additionalRequirements: [],
                useMultiUpload: false
            },
            {
                type: QuoteDocumentUploadTypes.Invoice,
                callToAction: 'Please upload a copy of the invoice you have provided to the customer',
                additionalRequirements: [],
                useMultiUpload: false
            }
        ],

        NSW_EHP: [
            {
                type: QuoteDocumentUploadTypes.CCEW,
                callToAction:
                    'Please upload a copy of the Certification of Compliance for Electrical Work (CCEW) for this installation',
                additionalRequirements: [
                    'The address and NMI match those on the Quote Record and Electricity Bill',
                    'The Battery, Panels, Inverter and DC Isolator are eligible for the program',
                    'The installer holds CEC battery endorsement'
                ],
                useMultiUpload: false
            },
            {
                type: QuoteDocumentUploadTypes.Invoice,
                callToAction: 'Please upload a copy of the invoice you have provided to the customer',
                additionalRequirements: [
                    'The invoice is in the name of loan applicant',
                    'The invoice price matches both Quote Record price and Customer Quote price'
                ],
                useMultiUpload: false
            },
            {
                type: QuoteDocumentUploadTypes.Bill,
                callToAction: "Please upload a copy of the customer's electricity bill",
                additionalRequirements: ['the bill is dated within the past twelve months'],
                useMultiUpload: false
            },
            {
                type: QuoteDocumentUploadTypes.SignedQuote,
                callToAction: 'Please upload a copy of the quote you have provided to the customer.',
                additionalRequirements: ['the quote contains an economic assessment'],
                useMultiUpload: false
            },
            {
                type: QuoteDocumentUploadTypes.SWMS,
                callToAction: 'Please upload a copy of the Safe Work Method Statements (SWMS)',
                additionalRequirements: ['the SWMS is dated on or before the installation date'],
                useMultiUpload: false
            },
            {
                type: QuoteDocumentUploadTypes.PTC,
                callToAction: 'Please upload a copy of the Permission to Connect (PTC) document',
                additionalRequirements: [],
                useMultiUpload: false
            },
            {
                type: QuoteDocumentUploadTypes.InstallationPhotograph,
                callToAction: 'Please upload photographs of the installation',
                additionalRequirements: [],
                useMultiUpload: true
            },
            {
                type: QuoteDocumentUploadTypes.BatterySystemManual,
                callToAction: 'Please upload the battery system manual & risk assessment relevant to this installation',
                additionalRequirements: ['the risk assessment has been uploaded with the system manual'],
                useMultiUpload: true
            },
            {
                type: QuoteDocumentUploadTypes.SolarPanelSystemManual,
                callToAction: 'Please upload the solar panel system manual relevant to this installation',
                additionalRequirements: [],
                useMultiUpload: true
            }
        ],

        SA_POWER_NETWORKS: [
            {
                type: QuoteDocumentUploadTypes.eCOC,
                callToAction:
                    'Please upload a copy of the Electronic Certificate of Compliance (eCoC) you have provided to the customer',
                additionalRequirements: [],
                useMultiUpload: true
            },
            {
                type: QuoteDocumentUploadTypes.Invoice,
                callToAction: 'Invoice showing all installed systems and any additional works',
                additionalRequirements: [],
                useMultiUpload: false
            },
            {
                type: QuoteDocumentUploadTypes.InstallationPhotograph,
                callToAction: 'Proof of install photos will be required depending on the systems installed',
                additionalRequirements: [
                    'A photo of the meter box showing the HEMS device',
                    'A photo of the Clipsal app successfully setup on the customers phone',
                    'A photo of of the hot water system and a photo of the switch for the hot water system installed (if heat pump/hot water installed)',
                    'A photo of the installed EV Charger system and a photo of the switch (if Ev charger as installed)',
                    'A photo of all installed aircon heads, compressors and Sensibo',
                    'A photo of the installed battery and a photo of the switch for the battery (if battery installed)',
                    'A photo of the installed inverter (if solar installed)'
                ],
                useMultiUpload: true
            }
        ]
    };

    uploadedDocuments: Record<string, QuoteUploadedDocumentModel[]> = {};
    documentsRetrieved = false;
    txt: TemplateText;

    get canDeleteDocument() {
        return this.quoteStatusService.canDeleteDocument(this.quoteStatus);
    }

    constructor(
        private quoteService: QuoteService,
        private quoteStatusService: QuoteStatusService,
        private errorCallback: ErrorCallbackGeneratorService,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.txt = new TemplateText(this.quoteFormModel.scope);
        var sapnSTCRequiredSystemTypes = [
            InstallationType.Battery,
            InstallationType.Solar,
            InstallationType.HeatPumpWaterHeater
        ];
        if (this.isSapnScheme()) {
            if (
                this.quoteFormModel.installationType.some(e => sapnSTCRequiredSystemTypes.includes(InstallationType[e]))
            ) {
                this.requiredDocuments[this.quoteFormModel.scope].push({
                    type: QuoteDocumentUploadTypes.SmallScaleTechnologyCertificate,
                    callToAction:
                        'Please upload a copy of Small Embedded Generation (SEG) approval for this installation',
                    additionalRequirements: [],
                    useMultiUpload: false
                });
            }
        }
        this.getUploadedDocuments();
    }

    getRequiredDocuments() {
        return this.requiredDocuments[this.quoteFormModel.scope];
    }

    onDeleteDocument(document: ApplicationDetailsSupportingDocumentationModel) {
        this.quoteService
            .deleteDocument(this.quoteExternalId, document.guid)
            .subscribe(
                result => this.getUploadedDocuments(),
                this.errorCallback.generate(`deleting document`, this.snackBar)
            );
    }

    isNswScheme(): boolean {
        return this.quoteFormModel.scope === Scheme.NewSouthWales;
    }

    isSaScheme(): boolean {
        return this.quoteFormModel.scope === Scheme.SouthAustralia;
    }

    isSapnScheme(): boolean {
        return this.quoteFormModel.scope === Scheme.SouthAustraliaPowerNetworks;
    }

    getUploadedDocuments() {
        this.documentsRetrieved = false;
        this.quoteService.getUploadedDocuments(this.quoteExternalId).subscribe(result => {
            this.setDocuments(result);
        }, this.errorCallback.generate('retrieving uploaded documents', this.snackBar));
    }

    setDocuments(newDocuments: QuoteUploadedDocumentModel[]) {
        let allDocumentsAvailable = true;
        this.requiredDocuments[this.quoteFormModel.scope].forEach(requiredDocType => {
            const docsOfType = newDocuments.filter(_ => _.documentType === requiredDocType.type);
            allDocumentsAvailable = allDocumentsAvailable && docsOfType.length > 0;
            this.uploadedDocuments[requiredDocType.type] = docsOfType;
        });
        this.onDocumentAvailabilityUpdated.next(allDocumentsAvailable);
        this.documentsRetrieved = true;
    }
}
