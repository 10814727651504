import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PortalActionsService {
    constructor(private httpClient: HttpClient) {}

    confirmInstallationAddress(
        applicationGuid: string,
        data: {
            firstName: string;
            lastName: string;
            installationAddress: string;
        }
    ) {
        return this.httpClient.post(
            `${environment.api.baseUrl}/portal/actions/confirmInstallationAddress/${applicationGuid}`,
            data
        );
    }

    confirmSystemDetails(
        applicationGuid: string,
        data: {
            systemType: string;
            batteryExternalId?: string;
            batteryQuantity?: number;
            batteryCapacity?: number;
            batterySerialNumber?: string;
            bessNumber?: string;
            nmi?: string;
            solarPanelExternalId?: string;
            solarPanelQuantity?: number;
            solarPanelCapacity?: number;
            inverterExternalId?: string;
            inverterSerialNumber?: string;
            hasExistingInverter: boolean;
            batteryCheckCode?: string;
            heatPumpModel?: string;
            heatPumpManufacturer?: string;
            evChargerModel?: string;
            evChargerManufacturer?: string;
            solarHotWaterModel?: string;
            solarHotWaterManufacturer?: string;
            airconModel?: string;
            airconManufacturer?: string;
            smartMeterModel?: string;
            smartMeterManufacturer?: string;
            poolPumpModel?: string;
            poolPumpManufacturer?: string;
            energyMonitoringSystemModel?: string;
            energyMonitoringSystemManufacturer?: string;
            inductionCooktopModel?: string;
            inductionCooktopManufacturer?: string;
        }
    ) {
        return this.httpClient.post(
            `${environment.api.baseUrl}/portal/actions/confirmSystemDetails/${applicationGuid}`,
            data
        );
    }
}
