import { AbstractControl } from '@angular/forms';
import { ActivityModel } from './quoteV2.model';

export interface QuoteFormModel {
    scope: Scheme;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    energyConcessionRecipient: boolean;
    statutoryPremiumFeedInMember: boolean;
    installationAddress: AddressModel;
    nmi: string;
    meterIdentificationNumber: string;
    installationAddressIsPredominantResidential: boolean;
    alternateNetwork: boolean;
    isCustomerJoiningVpp: boolean;
    vppOperator: string;
    hasHomeOwnershipOrInterest: boolean;
    confirmOwnerInstallationConsent: boolean;
    existingSolarPanels: boolean;
    newSolarPanelInstallationType: AdditionalInstallationType;
    newBatteryInstallationType: AdditionalInstallationType;
    existingBattery: boolean;
    intendedInstallationDate: Date;
    batteryPackage: string;
    solarPackage: string;
    inverterPackage: string;
    activities: ActivityModel[];
    dcIsolatorPackage: string;
    approvedBuilder: string;
    packagePrice: number;
    installationPrice: number;
    customerDeposit: number;
    solarPanelPrice: number;
    quoteValidity: Date;
    fairPricingConfirmation: Boolean;
    isPriceGstInc: boolean;
    isNewBuild: boolean;
    newBuildConfirmation: boolean;
    notApprovedForLowIncomeHouseholdsConfirmation: boolean;
    householdIncomeConfirmation: boolean;
    installationType: string[];
    homeOwnershipOrInterestStatus: string;
    previousExtraRequiredDeposit: number;
    previousInterestFreeLoanThreshold: number;
    noInterestBearingLoan: boolean;
    newSolarPanelCapacity: number;
    existingSolarPanelCapacity: number;
    existingBatteryCapacity: number;
    batterySerialNumber: string;
    batteryInverterSerialNumbers: string[];
    solarPanelSerialNumbers: string[];
    solarInverterSerialNumber: string;
    installationDate: Date;
    isEaas: boolean;
    providerCustomerId: string;
    providerInvoiceNumber: string;
    billingOwner: string;
    systemModelIdentifier_HEMSDevice: string;
    systemModelIdentifier_HeatPumpWaterHeater: string;
    systemModelIdentifier_EVCharger: string;
    systemModelIdentifier_InductionCooktop: string;
    systemModelIdentifier_SmartMeter: string;
    systemModelIdentifier_SplitSystemAirConditioner: string;
    price_HEMSDevice: number;
    price_HeatPumpWaterHeater: number;
    price_EVCharger: number;
    price_InductionCooktop: number;
    price_SmartMeter: number;
    price_SplitSystemAirConditioner: number;
}

export interface QuoteResultModel {
    scope: Scheme;
    quoteNumber: string;
    quoteExternalId: string;
}

export interface FinanceResultModel {
    applicationGuid: string;
}

export class AddressModel {
    unitNumber: string;
    streetNumber: string;
    street: string;
    suburb: string;
    postCode: string;
    state: string;
}

export class ActivitiesModel {
    activityType: string;
    associatedRebate: number;
    rebateAmount: number;
    vpp: string;
    systemAndInstallationCost: number;
    additionalCost: number;
}

export interface QuoteConfirmationModel {
    certifyInformation: boolean;
    certifyAddress: boolean;
}

export interface AddressDetailsModel {
    streetNumber: string;
    unitNumber: string;
    street: string;
    suburb: string;
    postCode: string;
    state: string;
}

export interface QuoteCreationModel {
    scope: Scheme;
    customerFirstName: string;
    customerLastName: string;
    customerEmail: string;
    customerMobile: string;
    isEnergyConcessionHolder: boolean;
    receivesDistributionFeedInTariff: boolean;
    installationAddress: AddressDetailsModel;
    nationalMeterIdentifier: string;
    meterIdentificationNumber: string;
    installationAddressIsPredominantResidential: boolean;
    isAlternateNetwork: boolean;
    vppOperator: string;
    hasHomeOwnershipOrInterest: boolean;
    confirmOwnerInstallationConsent: boolean;
    isHomeOwnershipOrInterestDirect: boolean;
    existingSolarPanels: boolean;
    existingBattery: boolean;
    intendedInstallationDate: Date;
    package: string;
    solarPackage: string;
    inverterPackageType: string;
    activities: ActivityModel[];
    dcIsolatorPackageType: string;
    builder: string;
    packagePrice: number;
    installationPrice: number;
    customerDeposit: number;
    solarPanelPrice: number;
    validUntil: Date;
    isPriceGstInc: boolean;
    isNewBuild: boolean;
    installationTypes: string[];
    previouslyApprovedLowIncomeHouseholdsScheme: boolean;
    extraRequiredDeposit: number;
    interestFreeLoanThreshold: number;
    newSolarPanelInstallationType: AdditionalInstallationType;
    newBatteryInstallationType: AdditionalInstallationType;
    newSolarPanelCapacity: number;
    existingSolarPanelCapacity: number;
    existingBatteryCapacity: number;
    isEaas: boolean;
    providerCustomerId: string;
    providerInvoiceNumber: string;
    billingOwner: string;
    systemDetails: {
        type: string;
        packageExternalId: string;
        price: number;
        systemModelIdentifier: string;
    }[];
}

export interface QuotePatchModel {
    customerFirstName: string;
    customerLastName: string;
    isEnergyConcessionHolder: boolean;
    receivesDistributionFeedInTariff: boolean;
    unitNumber: string;
    streetNumber: string;
    street: string;
    suburb: string;
    postCode: string;
    state: string;
    nationalMeterIdentifier: string;
    meterIdentificationNumber: string;
    isAlternateNetwork: boolean;
    vppOperator: string;
    hasHomeOwnershipOrInterest: boolean;
    confirmOwnerInstallationConsent: boolean;
    isHomeOwnershipOrInterestDirect: boolean;
    existingSolarPanels: boolean;
    newSolarPanelInstallationType: AdditionalInstallationType;
    newBatteryInstallationType: AdditionalInstallationType;
    existingBattery: boolean;
    intendedInstallationDate: Date;
    package: string;
    solarPanelPackageType: string;
    inverterPackageType: string;
    dcIsolatorPackageType: string;
    packagePrice: number;
    installationPrice: number;
    customerDeposit: number;
    solarPanelPrice: number;
    validUntil: Date;
    isNewBuild: boolean;
    builder: string;
    installationType: string;
    extraRequiredDeposit: number;
    interestFreeLoanThreshold: number;
    newSolarPanelCapacity: number;
    existingSolarPanelCapacity: number;
    existingBatteryCapacity: number;
    providerCustomerId: string;
    providerInvoiceNumber: string;
}

export interface QuoteRetrievedModel {
    quoteNumber: string;
    quoteFormModel: QuoteFormModel;
    currentStatus: string;
    applicationGuid: string;
    isEaas: boolean;
}

export enum QuoteDocumentUploadTypes {
    Invoice = 53,
    SEG = 54,
    eCOC = 55,
    Bill = 56,
    SignedQuote = 57,
    CCEW = 84,
    SWMS = 85,
    PTC = 86,
    InstallationPhotograph = 87,
    BatterySystemManual = 89,
    SolarPanelSystemManual = 90,
    SmallScaleTechnologyCertificate = 138
}

export const QuoteDocumentUploadTypeLabels = {
    SEG: 'Small Embedded Generation (SEG) approval',
    eCOC: 'Electronic Certificate of Compliance (eCoC)',
    Bill: 'electricity bill',
    SignedQuote: 'quote',
    Invoice: 'invoice',
    CCEW: 'Certification of Compliance for Electrical Work (CCEW)',
    SWMS: 'Safe Work Method Statements (SWMS)',
    PTC: 'Permission to Connect (PTC) document',
    InstallationPhotograph: 'installation photographs',
    BatterySystemManual: 'battery system manual',
    SolarPanelSystemManual: 'solar panel system manual',
    SmallScaleTechnologyCertificate: 'Small scale technology certificate (STC)'
};

export enum SapnMacActivityType {
    InstallSplitSystemAirConditioner = 'InstallSplitSystemAirConditioner',
    InstallHeatPumpWaterHeater = 'InstallHeatPumpWaterHeater',
    ConnectBatteryToVPP = 'ConnectBatteryToVPP',
    InstallEVCharger = 'InstallEVCharger',
    ConnectEVChargerToApprovedDemandAggregator = 'ConnectEVChargerToApprovedDemandAggregator',
    ConnectHVACToApprovedDemandAggregator = 'ConnectHVACToApprovedDemandAggregator',
    ConnectHotWaterSystemToApprovedDemandAggregator = 'ConnectHotWaterSystemToApprovedDemandAggregator',
    ConnectPoolPumpToApprovedDemandAggregator = 'ConnectPoolPumpToApprovedDemandAggregator',
    MoveHotWaterSystemToApprovedSolarSpongeTariff = 'MoveHotWaterSystemToApprovedSolarSpongeTariff',
    MovePropertyToTimeOfUseTariff = 'MovePropertyToTimeOfUseTariff',
    InstallRooftopSolarSystem = 'InstallRooftopSolarSystem',
    InstallBattery = 'InstallBattery',
    InstallInductionCooktop = 'InstallInductionCooktop',
    InstallSmartMeter = 'InstallSmartMeter',
    InstallHEMSDevice = 'InstallHEMSDevice',
    MiscItemsSwitchboardUpgradeSiteSpecificItems = 'MiscItemsSwitchboardUpgradeSiteSpecificItems'
}

export const SapnMacActivityTypesVppRequired = [SapnMacActivityType.ConnectBatteryToVPP];

export const SapnMacActivitySystemAndInstallationCostRequired = [
    SapnMacActivityType.InstallSplitSystemAirConditioner,
    SapnMacActivityType.InstallHeatPumpWaterHeater,
    SapnMacActivityType.InstallEVCharger,
    SapnMacActivityType.InstallRooftopSolarSystem,
    SapnMacActivityType.InstallBattery,
    SapnMacActivityType.InstallInductionCooktop,
    SapnMacActivityType.InstallSmartMeter,
    SapnMacActivityType.InstallHEMSDevice
];

export const SapnMacActivityAdditionalCosts = [
    SapnMacActivityType.InstallSplitSystemAirConditioner,
    SapnMacActivityType.InstallHeatPumpWaterHeater,
    SapnMacActivityType.InstallEVCharger,
    SapnMacActivityType.InstallRooftopSolarSystem,
    SapnMacActivityType.InstallBattery,
    SapnMacActivityType.InstallInductionCooktop,
    SapnMacActivityType.InstallSmartMeter,
    SapnMacActivityType.InstallHEMSDevice,
    SapnMacActivityType.MiscItemsSwitchboardUpgradeSiteSpecificItems
];

export const SapnMacActivityTypeLabel = {
    [SapnMacActivityType.InstallSplitSystemAirConditioner]: 'Install a Split System Air Conditioner',
    [SapnMacActivityType.InstallHeatPumpWaterHeater]: 'Install a Heat Pump Water Heater',
    [SapnMacActivityType.ConnectBatteryToVPP]: 'Connect a battery to a VPP',
    [SapnMacActivityType.InstallEVCharger]: 'Install an EV Charger',
    [SapnMacActivityType.ConnectEVChargerToApprovedDemandAggregator]:
        'Connect an EV Charger to an approved Demand Aggregator',
    [SapnMacActivityType.ConnectHVACToApprovedDemandAggregator]: 'Connect a HVAC to an approved Demand Aggregator',
    [SapnMacActivityType.ConnectHotWaterSystemToApprovedDemandAggregator]:
        'Connect a Hot Water System to an approved Demand Aggregator',
    [SapnMacActivityType.ConnectPoolPumpToApprovedDemandAggregator]:
        'Connect a Pool Pump to an approved Demand Aggregator',
    [SapnMacActivityType.MoveHotWaterSystemToApprovedSolarSpongeTariff]:
        'Move a Hot Water System to an approved Solar Sponge Tariff',
    [SapnMacActivityType.MovePropertyToTimeOfUseTariff]: 'Move property to Time-of-Use Tariff',
    [SapnMacActivityType.InstallRooftopSolarSystem]: 'Install a rooftop solar system',
    [SapnMacActivityType.InstallBattery]: 'Install a battery',
    [SapnMacActivityType.InstallInductionCooktop]: 'Install an induction cooktop',
    [SapnMacActivityType.InstallSmartMeter]: 'Install a smart meter',
    [SapnMacActivityType.InstallHEMSDevice]: 'Install a HEMS device',
    [SapnMacActivityType.MiscItemsSwitchboardUpgradeSiteSpecificItems]:
        'Misc items - Switchboard upgrade, site specific items'
};

export enum SapnMacActivitiesRebate {
    REPS = 'REPS',
    ARENA = 'ARENA',
    SA_GOV = 'SA_GOV',
    OTHER = 'OTHER'
}

export enum SapnMacActivityVpp {
    EnergyAustralia = 'EnergyAustralia',
    Amber = 'Amber'
}

export interface RequiredDocumentModel {
    type: QuoteDocumentUploadTypes;
    callToAction: string;
    additionalRequirements: string[];
    useMultiUpload: boolean;
}

export interface QuoteUploadedDocumentModel {
    guid: string;
    extension: string;
    mimeType: string;
    documentType: QuoteDocumentUploadTypes;
    documentTypeLabel: string;
    dateSubmitted: string;
}

export interface InstallationConfirmationConfirmModel {
    installationDate: Date;
}

export interface InstallationConfirmationModel {
    scope: Scheme;
    confirmModel: InstallationConfirmationConfirmModel;
    patchModel: InstallationConfirmationPatchModel;
}

export interface InstallationConfirmationPatchModel {
    batterySerialNumber: string;
    eCocCertificateNumber: string;
    solarPanelSerialNumbers: string[];
    batteryInverterSerialNumbers: string[];
    solarInverterSerialNumber: string;
}

export interface BasicPackageModel {
    name: string;
    shortName: string;
}

export interface PackageModel {
    name: string;
    shortName: string;
    capacitykWh: number;
    bessManufacturer: string;
    bessModelNo: string;
    batterySystemManufacturer: string;
    batterySystemModelNo: string;
    numberOfBessAndBs: number;
    inverterManufacturer: string;
    inverterModelNo: string;
    smartControllerMeterManufacturer: string;
    smartControllerMeterModelNo: string;
    currentSensorManufacturer: string;
    currentSensorModelNo: string;
    otherMajorComponentManufacturer1: string;
    otherMajorComponentModelNo1: string;
    otherMajorComponentManufacturer2: string;
    otherMajorComponentModelNo2: string;
    schemes: PackageSchemeModel[];
}

export interface PackageSchemeModel {
    scheme: Scheme;
    systemStatus: string;
}

export interface PackageWithCategoryModel {
    package: PackageModel;
    category: PackageCategory;
}

export interface CecPackageModel {
    packageExternalId: string;
    model: string;
    brand?: string;
    category: PackageCategory;
    energyCapacity?: number;
    outputPower?: number;
    series?: string;
    moduleNumber?: string;
}

export interface CecInstallerModel {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    mobile: string;
    solarAccreditationNumber: string;
    installerId: string;
    batteryType: string;
}

export interface BuilderModel {
    name: string;
    shortName: string;
}

export const extraPackageDetailLabels = {
    bessManufacturer: 'BESS manufacturer',
    bessModelNo: 'BESS model no',
    batterySystemManufacturer: 'Battery system manufacturer',
    batterySystemModelNo: 'Battery system model no',
    numberOfBessAndBs: 'Number of BESS/Battery system',
    inverterManufacturer: 'Inverter  manufacturer',
    inverterModelNo: 'Inverter model no',
    smartControllerMeterManufacturer: 'Smart controller/meter manufacturer',
    smartControllerMeterModelNo: 'Smart controller/meter model no',
    currentSensorManufacturer: 'Current sensor manufacturer',
    currentSensorModelNo: 'Current sensor model no',
    otherMajorComponentManufacturer1: 'Other major component manufacturer',
    otherMajorComponentModelNo1: 'Other major component model no',
    otherMajorComponentManufacturer2: 'Other major component manufacturer',
    otherMajorComponentModelNo2: 'Other major component model no',
    brand: 'System manufacturer',
    model: 'System model',
    series: 'System series'
};

export interface SubsidyCalculationModel {
    scope: string;
    isEnergyConcessionHolder: boolean;
}

export interface AsyncConfig {
    isLoading: boolean;
}

export interface QuoteCalculationModel {
    financeAmount: number;
    balancePayable: number;
    totalGstExclusive: number;
    totalGstInclusive: number;
    estimatedSubsidy: number;
    packageTotalIncGstLessSubsidy: number;
    balancePayableInterestFree: number;
    balancePayableInterestBearing: number;
    additionalRequiredDeposit: number;
    interestFreeLoanCap: number;
    totalCustomerDeposit: number;
    minimumLoanAmount: number;
    balancePayableExcess: number;
    balancePayableExcessAboveLoanThreshold: number;
}

export interface QuoteReviewModel {
    formModel: QuoteFormModel;
    calculationModel: QuoteCalculationModel;
    selectedPackages: PackagesModel;
    selectBuilder: BuilderModel;
}

export interface NamedAbstractControl {
    name: string;
    control: AbstractControl;
}

export enum Scheme {
    SouthAustralia = 'SA_SUBSIDY',
    NewSouthWales = 'NSW_EHP',
    AglVpp = 'AGL_VPP_SUBSIDY',
    SouthAustraliaPowerNetworks = 'SA_POWER_NETWORKS'
}

export enum HomeOwnershipOrInterest {
    Direct = 'Direct',
    Indirect = 'Indirect',
    NoOwnership = 'NoOwnership'
}

export enum InstallationTypeV1 {
    NotSet = '',
    BatteryOnly = 'Battery',
    BatteryAndSolar = 'Battery and Solar panel'
}

export enum InstallationType {
    NotSet = '',
    Battery = 'Battery',
    Solar = 'Solar',
    HEMSDevice = 'HEMSDevice',
    HeatPumpWaterHeater = 'HeatPumpWaterHeater',
    EVCharger = 'EVCharger',
    InductionCooktop = 'InductionCooktop',
    SmartMeter = 'SmartMeter',
    SplitSystemAirConditioner = 'SplitSystemAirConditioner'
}

export enum AdditionalInstallationType {
    NotSet = '',
    Replacement = 'Replacement',
    Extension = 'Extension'
}

export enum PackageCategory {
    Battery = 'battery',
    SolarPanel = 'solar',
    Inverter = 'inverter',
    DcIsolator = 'dcIsolator'
}

export enum PackageRequestScope {
    OnlyForBroker = 'broker',
    ForAllBrokers = 'allbrokers'
}

export interface PackagesModel {
    battery: PackageModel | CecPackageModel;
    solar: PackageModel | CecPackageModel;
    inverter: PackageModel | CecPackageModel;
    dcIsolator: PackageModel | CecPackageModel;
}

export interface ApplicationQuote {
    applicationId: string;
    quoteCreated: string;
    amountRequested: number;
    productQuotes: ProductQuote[]; // ModelProductQuote
    additionalInformation: { [key: string]: string };
}

export interface ProductQuote {
    productId: number;
    name: string;
    description: string;
    term: number;
    loanAmount: number;
    annualRate: number;
    financierRate: number;
    rateDiscount?: number;
    loanOfferType?: string;
    rateType: string;
    repayment: number | null;
    repaymentFrequency: string;
    totalRepaid: number | null;
    effectiveRate: number;
    asicComparisonRate: number;
    upfrontFees: Fee[];
    repaymentFees: Fee[];
    balloonAmount?: number;
    balloonPercentage?: number;
}

interface Fee {
    name: string;
    amount: number;
    feeType: string;
    feePayer?: string;
    feeRecipient?: string;
}

interface RateFee {
    rate: number;
    modelFeeType: string;
    name: string;
}
