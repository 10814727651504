<h2 mat-dialog-title>
    {{data.title}}
</h2>
<mat-dialog-content>
    <p>
       {{data.action}}
    </p>
    <div>
      <button mat-raised-button color="{{data.defaultNo ? 'primary' : 'secondary'}}" mat-dialog-close>No</button>
      <button mat-button matDialogClose="confirm" color="{{data.defaultNo ? 'warn' : 'primary'}}">Yes</button>
    </div>
</mat-dialog-content>
  