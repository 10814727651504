import {
    AfterContentChecked,
    AfterViewChecked,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl, FormArray } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaReplayService, PortalConfigurationService, WorkflowManifestService } from '@brokerportal/common/services';
import { SubNavigationService } from '@brokerportal/modules/admin/services';
import { environment } from 'environments/environment';
import { Subject, Subscription } from 'rxjs';
import {
    AdditionalInstallationType,
    AsyncConfig,
    BuilderModel,
    CecPackageModel,
    HomeOwnershipOrInterest,
    homeOwnershipOrInterestLabels,
    InstallationType,
    PackageCategory,
    PackagesModel,
    PackageWithCategoryModel,
    QuoteCalculationModel,
    QuoteCalculationRequestModel,
    QuoteCalculator,
    QuoteCreateStepper,
    QuoteFormCrudMode,
    QuoteFormModel,
    QuoteRetrievedModel,
    QuoteReviewModel,
    SapnMacActivitiesRebate,
    SapnMacActivityAdditionalCosts,
    SapnMacActivitySystemAndInstallationCostRequired,
    SapnMacActivityType,
    SapnMacActivityTypeLabel,
    SapnMacActivityTypesVppRequired,
    SapnMacActivityVpp,
    Scheme
} from '../../models';
import {
    AddressValidationService,
    ErrorCallbackGeneratorService,
    QuoteService,
    QuoteStatusService,
    SubsidyPriceService
} from '../../services';
import { TemplateText } from '../../template-text';
import {
    acceptedValuesValidator,
    childrenRequiredValidator,
    conditionallyRequired,
    conditionallyRequiredTrue,
    dependentNonNegativeValidator,
    requiredWhen,
    requiredWhenDependentPositive,
    unacceptedValuesValidator,
    minimumNumberValidator
} from '../../validators';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog.component';
import { QuoteRenewalConfirmDialog } from '../confirm-dialog/confirm-dialog.quote.renewal.component';
import { FeedInDisclaimerDialog } from '../disclaimer/feed-in-disclaimer-dialog.component';
import { SubsidyPriceStep } from './../../models/subsidy-price.model';
import * as converter from '@brokerportal/common/utils/convertor';
import { debounceTime } from 'rxjs/operators';
import { AuthenticationService } from '@brokerportal/common/auth';
import { VenusDemandMapping } from '@brokerportal/common/auth/demands.const';
import { ManifestConfigurationContext, ProductTag, ManifestControlTarget } from '@brokerportal/common/enums';
import { ToExperience } from '@brokerportal/common/enums/product-tag.enum';
import { AssignableBillingOwners } from '@brokerportal/common/enums/billing-owner.enum';

@Component({
    selector: 'quote-form',
    templateUrl: './quote-form.component.html',
    styleUrls: ['./quote-form.component.scss']
})
export class QuoteFormComponent implements OnInit, AfterContentChecked, AfterViewChecked, OnDestroy {
    @Input()
    initialCrudMode: QuoteFormCrudMode;

    @Input()
    parentCrudMode: QuoteFormCrudMode;

    @Input()
    quoteExternalId: string;

    @Input()
    quoteNumber: string;

    @Input()
    quoteStatus: string;

    @Input()
    applicationGuid: string;

    @Input()
    quoteScope: string;

    @Input()
    isEaas: boolean;

    @Input()
    defaultVpp: string;

    @Input()
    billingOwner: string;

    quoteCalculationModel: QuoteCalculationModel = {
        totalGstExclusive: 0,
        totalGstInclusive: 0,
        estimatedSubsidy: 0,
        balancePayable: 0,
        financeAmount: 0,
        balancePayableInterestFree: 0,
        balancePayableInterestBearing: 0,
        additionalRequiredDeposit: 0,
        interestFreeLoanCap: 0,
        totalCustomerDeposit: 0,
        minimumLoanAmount: 0,
        balancePayableExcess: 0,
        balancePayableExcessAboveLoanThreshold: 0,
        packageTotalIncGstLessSubsidy: 0
    };

    private _quoteFormModel: QuoteFormModel;
    @Input()
    set quoteFormModel(value: QuoteFormModel) {
        this._quoteFormModel = value;
        if (this._quoteFormModel) {
            this.quoteForm.patchValue(this._quoteFormModel);
            this.quoteScope = this._quoteFormModel.scope;
        }
    }
    get quoteFormModel(): QuoteFormModel {
        return this._quoteFormModel;
    }

    get activities() {
        return this.field<FormArray>('activities');
    }

    @Input()
    externalSubmitErrors: string[];

    @Output()
    onFormSubmitted = new EventEmitter<QuoteReviewModel>();
    @Output()
    onToConfirmInstallation = new EventEmitter<any>();
    @Output()
    onCancelled = new EventEmitter<any>();
    @Output()
    onPackageChanged = new EventEmitter<PackageWithCategoryModel>();

    schemeCoverage: string[] = [];
    txt: TemplateText;
    selectedBuilder: BuilderModel;
    viewEditButtonText: string;
    hasErrorsToDisplay: boolean;
    hasErrorsOnSubmit: boolean;
    asyncConfig: AsyncConfig;
    currentCrudMode: QuoteFormCrudMode;
    // I know it's ridiculous to have this but it's to keep the current mode (Edit) after doc upload dialog pops up
    currentCrudModeBackUp: QuoteFormCrudMode;

    allDocumentsProvided = false;
    approvedBuilders: BuilderModel[] = [];

    selectedPackages: PackagesModel = {
        battery: null,
        solar: null,
        inverter: null,
        dcIsolator: null
    };

    propertyOwnershipStatuses = [
        { name: 'Has ownership or ownership interest', value: true },
        { name: 'No ownership, no ownership interest', value: false }
    ];

    installationTypes = [
        { name: 'Battery', value: InstallationType.Battery, mapActivity: SapnMacActivityType.InstallBattery },
        {
            name: 'Solar panel',
            value: InstallationType.Solar,
            mapActivity: SapnMacActivityType.InstallRooftopSolarSystem
        },
        { name: 'HEMS device', value: InstallationType.HEMSDevice, mapActivity: SapnMacActivityType.InstallHEMSDevice },
        {
            name: 'Heat pump water heater',
            value: InstallationType.HeatPumpWaterHeater,
            mapActivity: SapnMacActivityType.InstallHeatPumpWaterHeater
        },
        { name: 'EV charger', value: InstallationType.EVCharger, mapActivity: SapnMacActivityType.InstallEVCharger },
        {
            name: 'Induction cooktop',
            value: InstallationType.InductionCooktop,
            mapActivity: SapnMacActivityType.InstallInductionCooktop
        },
        { name: 'Smart meter', value: InstallationType.SmartMeter, mapActivity: SapnMacActivityType.InstallSmartMeter },
        {
            name: 'Split system air conditioner',
            value: InstallationType.SplitSystemAirConditioner,
            mapActivity: SapnMacActivityType.InstallSplitSystemAirConditioner
        }
    ];

    panelInstallationTypes = [
        { name: 'Replacement of existing solar system', value: AdditionalInstallationType.Replacement },
        { name: 'Extension of existing solar system', value: AdditionalInstallationType.Extension }
    ];

    batteryInstallationTypes = [
        { name: 'Replacement of existing battery system', value: AdditionalInstallationType.Replacement },
        { name: 'Extension of existing battery system', value: AdditionalInstallationType.Extension }
    ];

    homeOwnershipOrInterestStatuses = [
        { name: homeOwnershipOrInterestLabels[HomeOwnershipOrInterest.Direct], value: HomeOwnershipOrInterest.Direct },
        {
            name: homeOwnershipOrInterestLabels[HomeOwnershipOrInterest.Indirect],
            value: HomeOwnershipOrInterest.Indirect
        },
        {
            name: homeOwnershipOrInterestLabels[HomeOwnershipOrInterest.NoOwnership],
            value: HomeOwnershipOrInterest.NoOwnership
        }
    ];

    nmiPatterns = {
        [Scheme.SouthAustralia]: /^200[12][0-9]{6}([0-9]{1}|[A-Ha-hJ-Nj-nP-Zp-z1-9]{2})?$/,
        [Scheme.NewSouthWales]: /^4((5080[0-9]{5})|((001|102|103|104|204|407)[0-9]{6})|(31[0-9]{7})|(60810[0-8][0-9]{3}))([0-9]{1}|[A-Ha-hJ-Nj-nP-Zp-z1-9]{2})?$/
    };

    maxValidityMonths = 6;
    currencyPattern = /^[0-9]+(\.[0-9][0-9]?)?$/;
    decimalPattern = /^[0-9]{1,5}(\.[0-9][0-9]?)?$/;
    namePattern = /^[ '&a-zA-Z\-]{2,30}$/;
    // See https://www.aemo.com.au/-/media/Files/Electricity/NEM/Retail_and_Metering/Metering-Procedures/2018/MSATS-National-Metering-Identifier-Procedure.pdf
    postCodeRegex = () => {
        if (this.quoteScope === Scheme.SouthAustraliaPowerNetworks) {
            return /^5\d{3}$/;
        }
        return /^[0-9]{4}$/;
    };
    confirmResponse = 'confirm';
    allowedStates = [];
    subsidyRate: SubsidyPriceStep;
    readonly converter = converter;

    timestamp = new Date();
    today: Date;
    maxValidityDate: Date;
    isMobile: boolean;
    minimumLoanAmountNsw = 2001;
    manifestControlTargets: Set<string> = new Set<string>();

    private readonly approvedVpps: string[] = ['Tesla', 'EnergyAustralia'];

    quoteForm = new FormGroup({
        scope: new FormControl(''),
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        mobile: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
        energyConcessionRecipient: new FormControl(false, []),
        statutoryPremiumFeedInMember: new FormControl(false, []),
        installationAddress: new FormGroup(
            {
                unitNumber: new FormControl('', []),
                streetNumber: new FormControl('', [Validators.required]),
                street: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)]),
                suburb: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)]),
                postCode: new FormControl(''),
                state: new FormControl('', [Validators.required, acceptedValuesValidator(this.allowedStates, true)])
            },
            childrenRequiredValidator(['streetNumber', 'street', 'suburb', 'postCode', 'state'])
        ),
        nmi: new FormControl('', [conditionallyRequired(['isNewBuild', 'alternateNetwork'], [false, false])]),
        meterIdentificationNumber: new FormControl('', [
            conditionallyRequired(['isNewBuild', 'alternateNetwork'], [false, true]),
            Validators.pattern('^[0-9]{1,50}$')
        ]),
        installationAddressIsPredominantResidential: new FormControl(false, [
            requiredWhen(() => this.quoteScope !== Scheme.SouthAustraliaPowerNetworks)
        ]),
        alternateNetwork: new FormControl(false, []),
        isCustomerJoiningVpp: new FormControl(false),
        vppOperator: new FormControl(null, [conditionallyRequired(['isCustomerJoiningVpp'], [true])]),
        hasHomeOwnershipOrInterest: new FormControl('', conditionallyRequired(['scope'], [Scheme.SouthAustralia])),
        batteryPackage: new FormControl(''),
        solarPackage: new FormControl(null, []),
        inverterPackage: new FormControl(null, []),
        activities: new FormArray([]),
        dcIsolatorPackage: new FormControl(
            null,
            conditionallyRequired(['installationType', 'scope'], ['BatteryAndSolarSystem', Scheme.NewSouthWales])
        ),
        approvedBuilder: new FormControl('', [conditionallyRequiredTrue('isNewBuild', true)]),

        existingSolarPanels: new FormControl(false, [
            conditionallyRequired(['installationType', 'scope'], ['BatteryAndSolarSystem', Scheme.NewSouthWales])
        ]),
        existingBattery: new FormControl(false, []),
        intendedInstallationDate: new FormControl(
            '',
            requiredWhen(() => this.quoteScope !== Scheme.SouthAustraliaPowerNetworks)
        ),
        packagePrice: new FormControl('', [Validators.pattern(this.currencyPattern)]),
        installationPrice: new FormControl('', [
            requiredWhen(() => this.quoteScope !== Scheme.SouthAustraliaPowerNetworks),
            Validators.pattern(this.currencyPattern)
        ]),
        solarPanelPrice: new FormControl('', [
            Validators.pattern(this.currencyPattern),
            conditionallyRequired(['installationType'], ['BatteryAndSolarSystem'])
        ]),
        customerDeposit: new FormControl('', [Validators.required, Validators.pattern(this.currencyPattern)]),
        quoteValidity: new FormControl(''),
        fairPricingConfirmation: new FormControl(false, [Validators.requiredTrue]),
        isPriceGstInc: new FormControl(true),
        isNewBuild: new FormControl(false, [Validators.required]),
        newBuildConfirmation: new FormControl(false, [conditionallyRequiredTrue('isNewBuild', true)]),
        notApprovedForLowIncomeHouseholdsConfirmation: new FormControl(false, [
            conditionallyRequiredTrue('scope', Scheme.NewSouthWales)
        ]),
        householdIncomeConfirmation: new FormControl(false, [conditionallyRequiredTrue('scope', Scheme.NewSouthWales)]),
        installationType: new FormControl(
            [],
            [
                conditionallyRequiredTrue('scope', Scheme.NewSouthWales),
                requiredWhen(
                    () =>
                        this.quoteScope === Scheme.SouthAustralia ||
                        (this.quoteScope === Scheme.SouthAustraliaPowerNetworks && this.isCrudCreateMode())
                )
            ]
        ),
        systemModelIdentifier_HEMSDevice: new FormControl(''),
        systemModelIdentifier_HeatPumpWaterHeater: new FormControl(''),
        systemModelIdentifier_EVCharger: new FormControl(''),
        systemModelIdentifier_InductionCooktop: new FormControl(''),
        systemModelIdentifier_SmartMeter: new FormControl(''),
        systemModelIdentifier_SplitSystemAirConditioner: new FormControl(''),
        price_HEMSDevice: new FormControl(''),
        price_HeatPumpWaterHeater: new FormControl(''),
        price_EVCharger: new FormControl(''),
        price_InductionCooktop: new FormControl(''),
        price_SmartMeter: new FormControl(''),
        price_SplitSystemAirConditioner: new FormControl(''),
        newSolarPanelInstallationType: new FormControl('', [
            conditionallyRequired(
                ['existingSolarPanels', 'installationType', 'scope'],
                [true, 'BatteryAndSolarSystem', Scheme.NewSouthWales]
            )
        ]),
        newBatteryInstallationType: new FormControl('', [
            conditionallyRequired(['existingBattery', 'scope'], [true, Scheme.NewSouthWales])
        ]),
        homeOwnershipOrInterestStatus: new FormControl('', [
            conditionallyRequiredTrue('scope', Scheme.NewSouthWales),
            unacceptedValuesValidator([HomeOwnershipOrInterest.NoOwnership])
        ]),
        noInterestBearingLoan: new FormControl(null, [
            requiredWhenDependentPositive(this.quoteCalculationModel, 'balancePayableExcessAboveLoanThreshold')
        ]),
        previousExtraRequiredDeposit: new FormControl(),
        previousInterestFreeLoanThreshold: new FormControl(),
        newSolarPanelCapacity: new FormControl('', [
            conditionallyRequired(['installationType', 'scope'], ['BatteryAndSolarSystem', Scheme.NewSouthWales]),
            Validators.pattern(this.decimalPattern)
        ]),
        existingSolarPanelCapacity: new FormControl('', [
            conditionallyRequired(['existingSolarPanels', 'scope'], [true, Scheme.NewSouthWales]),
            Validators.pattern(this.decimalPattern)
        ]),
        existingBatteryCapacity: new FormControl('', [
            conditionallyRequired(['existingBattery', 'scope'], [true, Scheme.NewSouthWales]),
            Validators.pattern(this.decimalPattern)
        ]),
        estimatedSubsidy: new FormControl('', [
            dependentNonNegativeValidator(this.quoteCalculationModel, 'packageTotalIncGstLessSubsidy')
        ]),
        isEaas: new FormControl(''),
        providerCustomerId: new FormControl('', conditionallyRequiredTrue('isEaas', true)),
        providerInvoiceNumber: new FormControl(''),
        billingOwner: new FormControl(''),
        activityRebateAmount: new FormControl(0, [Validators.pattern(this.currencyPattern), Validators.max(2500)]),
        systemAndInstallationCost: new FormControl(0, [Validators.pattern(this.currencyPattern), Validators.required]),
        additionalCost: new FormControl(0, [Validators.pattern(this.currencyPattern), Validators.required])
    });

    private _mediaSubscription: Subscription;
    submissionErrorSubject: Subject<void>;

    sapnMacActivityTypeLabel = SapnMacActivityTypeLabel;
    sapnMacActivityType = Object.values(SapnMacActivityType);
    sapnMacActivitiesRebate = Object.values(SapnMacActivitiesRebate);
    sapnMacActivitiesVpp = Object.values(SapnMacActivityVpp);

    selectedActivityType: string;
    selectedActivityRebateType: string;
    selectedActivityVpp: string;

    env = environment;
    actionPermissions = {
        canPatchQuote: false,
        canCancelQuote: false,
        canConfirmInstallation: false
    };
    providerName: string;

    subsidyCountMessage: string = null;

    totalSystemAndInstallationCosts = 0;
    totalAdditionalCost = 0;
    totalActivityRebate = 0;

    get ManifestControlTarget() {
        return ManifestControlTarget;
    }

    get systemType(): string | null {
        return this.quoteForm?.get('systemType').value;
    }

    constructor(
        private quoteService: QuoteService,
        public quoteStatusService: QuoteStatusService,
        private subNavigationService: SubNavigationService,
        private mediaReplayService: MediaReplayService,
        private errorCallback: ErrorCallbackGeneratorService,
        private feedInDisclaimerDialog: MatDialog,
        private cancelDialog: MatDialog,
        private snackBar: MatSnackBar,
        private addressValidation: AddressValidationService,
        private subsidyPriceService: SubsidyPriceService,
        private quoteRenewalConfirmDialog: MatDialog,
        private authService: AuthenticationService,
        private portalConfigService: PortalConfigurationService,
        private workflowManifestService: WorkflowManifestService
    ) {
        this.today = new Date(this.timestamp.getFullYear(), this.timestamp.getMonth(), this.timestamp.getDate());
        this.maxValidityDate = new Date(
            this.timestamp.getFullYear(),
            this.timestamp.getMonth() + this.maxValidityMonths,
            this.timestamp.getDate()
        );

        this.viewEditButtonText = 'Edit';
        this.asyncConfig = {
            isLoading: false
        };

        authService.hasDemand(VenusDemandMapping.CanCancelQuote).then(value => {
            this.actionPermissions.canCancelQuote = value;
        });
        authService.hasDemand(VenusDemandMapping.CanPatchQuote).then(value => {
            this.actionPermissions.canPatchQuote = value;
        });
        authService.hasDemand(VenusDemandMapping.CanConfirmInstallation).then(value => {
            this.actionPermissions.canConfirmInstallation = value;
        });
    }

    ngOnInit(): void {
        this.portalConfigService.get().subscribe(portalConfig => {
            this.providerName = portalConfig.partner?.name;
            let productTag = this.schemeToProductTag(this.quoteScope);
            let broker = portalConfig?.brokers?.find(broker => {
                return broker.productTag === productTag && broker.isDirect === true;
            });

            if (!broker) return;
            this.workflowManifestService
                .GetConfigurationsByBrokerIdAndExperience(broker.id, ToExperience(productTag))
                .subscribe(configurations => {
                    let brokerManifestConfig = configurations.find(
                        config => config.context === ManifestConfigurationContext.BorrowerApplication
                    );
                    // calling set constructor with null/undefined will just return a set with 0 elements.
                    this.manifestControlTargets = new Set(brokerManifestConfig?.brokerManifestControlTargets);
                });
        });

        this.isEaas = this.isEaas ? this.isEaas : false; // cast isEaas to boolean
        this.submissionErrorSubject = new Subject<void>();

        this._mediaSubscription = this.mediaReplayService.isMobile$.subscribe(isMobile => (this.isMobile = isMobile));

        this.getSubsidy();

        this.setInitialFormValues();
        this.loadQuoteResources();
        if (this.isCrudCreateMode() && this.isSaScheme() && this.isSubsidyRateAnnounced()) {
            this.getCurrentSubsidyRate();
        }
        (this.quoteForm.controls['installationAddress'] as FormGroup).controls['postCode'].setValidators([
            Validators.required,
            Validators.pattern(this.postCodeRegex()),
            acceptedValuesValidator(this.schemeCoverage, true)
        ]);
        (this.quoteForm.controls['installationAddress'] as FormGroup).controls['postCode'].updateValueAndValidity();
        this.quoteForm.get('isCustomerJoiningVpp').valueChanges.subscribe((value: boolean) => {
            if (!value) {
                this.quoteForm.get('vppOperator').setValue('');
            }
        });

        this.quoteForm.get('installationType').valueChanges.subscribe((value: string[]) => {
            if (!value.includes(InstallationType.Solar)) {
                this.field('solarPanelPrice').setValue('0');
            }
        });

        if (this.isCrudEditMode()) {
            this.prefillActivityTableFromExistingQuote();
        }
    }

    private getCurrentSubsidyRate() {
        this.subsidyPriceService
            .getStepsByScope(this.quoteScope)
            .pipe(debounceTime(5000))
            .subscribe(steps => {
                this.subsidyRate = steps
                    .sort((a, b) => a.priority - b.priority)
                    .find(step => step.remainingSubsidies > 0);

                this.subsidyCountMessage = this.generateSubsidyCountMessage(this.subsidyRate);
            });
    }

    generateSubsidyCountMessage(subsidyRate: SubsidyPriceStep) {
        if (!subsidyRate) return null;

        const { totalSubsidies, remainingSubsidies } = subsidyRate;
        const hideCount = totalSubsidies > 900000;
        if (hideCount) return null;

        let subsidyRemaining = `${remainingSubsidies}`;
        if (100 > remainingSubsidies) {
            subsidyRemaining = 'less than 100';
        }

        return `Subsidies remaining at this subsidy rate: ${subsidyRemaining}`;
    }

    setInitialFormValues() {
        this.hasErrorsOnSubmit = false;
        this.currentCrudMode = this.initialCrudMode;
        this.currentCrudModeBackUp = this.initialCrudMode;

        if (!this.field('scope').value) {
            this.field('scope').setValue(this.quoteScope);
        }

        if (!this.field('isEaas').value) {
            this.field('isEaas').setValue(this.isEaas);
        }

        if (this.shouldLoadDefaultVppAndBillingOwner()) {
            if (this.approvedVpps.includes(this.defaultVpp)) {
                this.field('isCustomerJoiningVpp').setValue(true);
                this.field('vppOperator').setValue(this.defaultVpp);
            }
            if (AssignableBillingOwners.includes(this.billingOwner)) {
                this.field('billingOwner').setValue(this.billingOwner);
            }
        }

        this.txt = new TemplateText(this.field('scope').value, this.isPriceGstInc());
        this.setDefaultQuoteValidity();
        this.setNmiValidation();
        this.setCustomerDepositValidation();

        if (this.allowedStates.length === 0) {
            this.allowedStates.push(...this.addressValidation.getAllowedStates(this.field('scope').value));
        }

        this.initialiseQuoteCalculation();
    }

    setNmiValidation() {
        const field = this.field('nmi');
        const scope = this.field('scope').value;
        field.setValidators([
            field.validator,
            Validators.pattern(this.nmiPatterns[scope]),
            requiredWhen(() => this.quoteStatusService.isConfirmable(this.quoteStatus))
        ]);
    }

    setCustomerDepositValidation() {
        const field = this.field('customerDeposit');
        const scope = this.field('scope').value;
        if (scope !== Scheme.SouthAustraliaPowerNetworks) {
            field.setValidators([
                field.validator,
                minimumNumberValidator(
                    this.quoteCalculationModel,
                    'balancePayableInterestFree',
                    scope === Scheme.NewSouthWales ? this.minimumLoanAmountNsw : 0
                )
            ]);
        }
    }

    setDefaultQuoteValidity() {
        if (!this.isNswScheme()) {
            return;
        }
        const nswDefaultQuoteValidityDays = 90;
        const validDate = new Date();
        validDate.setDate(validDate.getDate() + nswDefaultQuoteValidityDays);
        this.field('quoteValidity').setValue(validDate);
    }

    initialiseQuoteCalculation() {
        this.handleQuoteAmountChange(null, null, false);
    }

    loadQuoteResources() {
        if (this.isNswScheme()) {
            this.getSchemeCoverage();
        }
        if (this.isSaScheme()) {
            this.getApprovedBuilders();
        }

        if (this.quoteFormModel) {
            this.loadApprovedBuilder(this.quoteFormModel.approvedBuilder);
        }
    }

    homeOwnershipOrInterestStatusChanged(selectedValue: any) {
        this.field('homeOwnershipOrInterestStatus').setValue(selectedValue);
        const hasHomeOwnershipOrInterest =
            [HomeOwnershipOrInterest.Direct, HomeOwnershipOrInterest.Indirect].indexOf(selectedValue) >= 0;
        this.field('hasHomeOwnershipOrInterest').setValue(hasHomeOwnershipOrInterest);
    }

    handleQuoteAmountChange(newValue: string, updatedField: string, isManualChange: boolean = true) {
        const isViewMode = this.isCrudViewMode();

        const calculationRequestModel = {
            installationPrice: this.fieldNumeric('installationPrice'),
            packagePrice: this.fieldNumeric('packagePrice'),
            solarPanelPrice: this.fieldNumeric('solarPanelPrice'),
            price_HEMSDevice: this.fieldNumeric('price_HEMSDevice'),
            price_HeatPumpWaterHeater: this.fieldNumeric('price_HeatPumpWaterHeater'),
            price_EVCharger: this.fieldNumeric('price_EVCharger'),
            price_InductionCooktop: this.fieldNumeric('price_InductionCooktop'),
            price_SmartMeter: this.fieldNumeric('price_SmartMeter'),
            price_SplitSystemAirConditioner: this.fieldNumeric('price_SplitSystemAirConditioner'),
            estimatedSubsidy: this.quoteCalculationModel.estimatedSubsidy,
            customerDeposit: this.fieldNumeric('customerDeposit'),
            scope: this.field('scope').value,
            installationType: this.field('installationType').value,
            isGstInc: this.isPriceGstInc(),
            noInterestBearingLoan: this.field('noInterestBearingLoan').value === false ? false : true,
            useInterestFreeLoanThreshold: isViewMode ? this.quoteFormModel.previousInterestFreeLoanThreshold : null,
            useExtraRequiredDeposit: isViewMode ? this.quoteFormModel.previousExtraRequiredDeposit : null
        };

        if (updatedField !== null) {
            calculationRequestModel[updatedField] = parseFloat(newValue) >= 0 ? parseFloat(newValue) : 0;
        }

        this.updateQuoteCalculation(calculationRequestModel, isManualChange);
    }

    derivedFinanceChangedFromLastQuote(): boolean {
        if (!this.quoteFormModel) {
            return false;
        }

        return (
            this.quoteFormModel.previousExtraRequiredDeposit !== this.quoteCalculationModel.additionalRequiredDeposit ||
            this.quoteFormModel.previousInterestFreeLoanThreshold !== this.quoteCalculationModel.interestFreeLoanCap
        );
    }

    isMacActivityVppRequired() {
        if (!this.selectedActivityType) return false;
        return SapnMacActivityTypesVppRequired.includes(SapnMacActivityType[this.selectedActivityType]);
    }

    isMacActivitySystemAndInstallationCostRequired() {
        if (!this.selectedActivityType) return false;
        return SapnMacActivitySystemAndInstallationCostRequired.includes(
            SapnMacActivityType[this.selectedActivityType]
        );
    }

    isMacActivityAdditionalCostRequired() {
        if (!this.selectedActivityType) return false;
        return SapnMacActivityAdditionalCosts.includes(SapnMacActivityType[this.selectedActivityType]);
    }

    addActivity() {
        const currentRebateAmount = this.field<FormArray>('activities').controls.reduce((prev, curr) => {
            if (curr.get('associatedRebate').value === this.selectedActivityRebateType) {
                return prev + curr.get('rebateAmount').value;
            }
            return prev;
        }, 0);

        if (currentRebateAmount + this.field('activityRebateAmount').value > 2500) {
            this.field('activityRebateAmount').setErrors({ amountExceed: true });
            return;
        }

        if (
            !this.selectedActivityType ||
            !this.selectedActivityRebateType ||
            this.field('activityRebateAmount').value === null
        ) {
            this.field('activityRebateAmount').setErrors({ missingFields: true });
            return;
        }

        if (
            !this.selectedActivityVpp &&
            SapnMacActivityTypesVppRequired.includes(SapnMacActivityType[this.selectedActivityType])
        ) {
            this.field('activityRebateAmount').setErrors({ vppRequired: true });
            return;
        }

        if (
            this.isMacActivitySystemAndInstallationCostRequired() &&
            this.field('systemAndInstallationCost').value === null
        ) {
            this.field('systemAndInstallationCost').setErrors({ systemAndInstallationCostRequired: true });
            return;
        }

        if (this.isMacActivityAdditionalCostRequired() && this.field('additionalCost').value === null) {
            this.field('additionalCost').setErrors({ additionalCostRequired: true });
            return;
        }

        this.field<FormArray>('activities').push(
            new FormGroup({
                activityType: new FormControl(this.selectedActivityType),
                associatedRebate: new FormControl(this.selectedActivityRebateType),
                rebateAmount: new FormControl(this.field('activityRebateAmount').value),
                vpp: new FormControl(this.selectedActivityVpp),
                systemAndInstallationCost: new FormControl(this.field('systemAndInstallationCost').value),
                additionalCost: new FormControl(this.field('additionalCost').value),
                subtotal: new FormControl(
                    this.field('systemAndInstallationCost').value +
                        this.field('additionalCost').value -
                        this.field('activityRebateAmount').value
                )
            })
        );

        //reset value
        this.field('activityRebateAmount').setValue(0);
        this.field('systemAndInstallationCost').setValue(0);
        this.field('additionalCost').setValue(0);
        this.selectedActivityVpp = null;
        this.syncActivityToInstallationType();

        this.totalSystemAndInstallationCosts = this.getTotalSystemAndInstallationCosts();
        this.totalActivityRebate = this.getTotalActivityRebate();
        this.totalAdditionalCost = this.getTotalAdditionalCost();
    }

    deleteActivity(index: number) {
        this.field<FormArray>('activities').removeAt(index);
        this.syncActivityToInstallationType();
        this.totalSystemAndInstallationCosts = this.getTotalSystemAndInstallationCosts();
        this.totalActivityRebate = this.getTotalActivityRebate();
        this.totalAdditionalCost = this.getTotalAdditionalCost();
    }

    syncActivityToInstallationType() {
        const newInstallationTypes = [];
        this.field<FormArray>('activities').controls.forEach((e: FormGroup) => {
            const mapInstallationType = this.installationTypes.find(
                i => i.mapActivity === e.controls['activityType'].value
            );
            if (mapInstallationType) {
                newInstallationTypes.push(mapInstallationType.value);
            }
        });

        this.field('installationType').setValue(newInstallationTypes);
    }

    getTotalSystemAndInstallationCosts() {
        return this.field<FormArray>('activities').controls?.reduce(
            (prev, curr: FormGroup) => prev + curr.get('systemAndInstallationCost').value,
            0
        );
    }

    getTotalAdditionalCost() {
        return this.field<FormArray>('activities').controls?.reduce(
            (prev, curr: FormGroup) => prev + curr.get('additionalCost').value,
            0
        );
    }

    getTotalActivityRebate() {
        return this.field<FormArray>('activities').controls?.reduce(
            (prev, curr: FormGroup) => prev + curr.get('rebateAmount').value,
            0
        );
    }

    getTotalAmountOutstanding() {
        return (
            this.totalSystemAndInstallationCosts +
            this.totalAdditionalCost -
            this.field('customerDeposit').value -
            this.totalActivityRebate
        );
    }
    getControlName(c: AbstractControl): string | null {
        const formGroup = c.parent.controls;
        return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
    }
    updateQuoteCalculation(requestModel: QuoteCalculationRequestModel, isManualChange: boolean) {
        const newQuote = new QuoteCalculator(requestModel).calculate();
        Object.assign(this.quoteCalculationModel, newQuote);

        this.updateExtraPaymentTypeValueAndState();
        this.field('customerDeposit').updateValueAndValidity();
        this.field('noInterestBearingLoan').updateValueAndValidity();
        this.field('estimatedSubsidy').updateValueAndValidity();

        if (isManualChange || this.derivedFinanceChangedFromLastQuote()) {
            this.field('fairPricingConfirmation').setValue(false);
        }
    }

    private updateExtraPaymentTypeValueAndState() {
        if (!this.isEaas) return;

        const installationType = this.field('installationType').value as string[];
        const noInterestBearingLoanCtrl = this.field<FormControl>('noInterestBearingLoan');
        if (
            installationType.includes(InstallationType.Battery) &&
            this.quoteCalculationModel.totalGstInclusive > 9000
        ) {
            noInterestBearingLoanCtrl.setValue(true, { emitEvent: false });
            noInterestBearingLoanCtrl.disable();
        } else if (
            installationType.includes(InstallationType.Solar) &&
            this.quoteCalculationModel.totalGstInclusive > 14000
        ) {
            noInterestBearingLoanCtrl.setValue(true, { emitEvent: false });
            noInterestBearingLoanCtrl.disable();
        } else {
            noInterestBearingLoanCtrl.enable();
        }
    }

    ngAfterContentChecked(): void {
        this.currentCrudModeBackUp = this.parentCrudMode ? this.parentCrudMode : this.currentCrudModeBackUp;
        this.parentCrudMode = null;
        this.currentCrudMode = this.currentCrudModeBackUp;
    }

    ngAfterViewChecked(): void {
        this.ngAfterContentChecked();
    }

    field<T extends AbstractControl>(name) {
        return this.quoteForm.get(name) as T;
    }

    fieldNumeric(fieldName: string): number {
        return parseFloat(this.field(fieldName).value) || 0;
    }

    nonNegative(input: number) {
        return input > 0 ? input : 0;
    }

    headerTitle() {
        const nswSchemeName = this.isEaas ? 'NSW EHP EaaS' : 'NSW Empowering Homes';
        const getCreateModeHeaderTitle = () => {
            if (this.isNswScheme()) return nswSchemeName;
            if (this.isSapnScheme()) return 'SAPN';
            return 'a';
        };
        const getEditModeHeaderTitle = () => {
            if (this.isNswScheme()) return nswSchemeName;
            if (this.isSapnScheme()) return 'SAPN';
            return '';
        };
        if (this.currentCrudMode === QuoteFormCrudMode.Create) {
            return `Create ${getCreateModeHeaderTitle()} Quote Record`;
        } else if (this.currentCrudMode === QuoteFormCrudMode.View || this.currentCrudMode === QuoteFormCrudMode.Edit) {
            return `Edit ${getEditModeHeaderTitle()} Quote Record`;
        }
    }

    isCrudCreateMode() {
        return this.currentCrudMode === QuoteFormCrudMode.Create;
    }

    isCrudViewMode() {
        return this.currentCrudMode === QuoteFormCrudMode.View;
    }

    isCrudEditMode() {
        return this.currentCrudMode === QuoteFormCrudMode.Edit;
    }

    isPriceGstInc() {
        return this.isCrudCreateMode() || (this.quoteFormModel && this.quoteFormModel.isPriceGstInc);
    }

    isExpiredQuote(): boolean {
        return this.quoteStatusService.isExpired(this.quoteStatus);
    }

    isNswScheme() {
        return this.field('scope').value === Scheme.NewSouthWales;
    }

    isSaScheme() {
        return this.field('scope').value === Scheme.SouthAustralia;
    }

    isSapnScheme() {
        return this.field('scope').value === Scheme.SouthAustraliaPowerNetworks;
    }

    schemeToProductTag(scheme: string) {
        switch (scheme) {
            case Scheme.NewSouthWales:
                return ProductTag.NSWRenewableEnergy;
            case Scheme.SouthAustralia:
                return ProductTag.SARenewableEnergy;
            default:
                return ProductTag.Unknown;
        }
    }

    isSubsidyRateAnnounced() {
        return true;
    }

    isInstallationTypeNotSet() {
        return this.field('installationType').value === InstallationType.NotSet;
    }

    hasInterestBearingPortion() {
        return this.quoteCalculationModel.balancePayableInterestBearing > 0;
    }

    shouldLoadDefaultVppAndBillingOwner() {
        return !!this.isEaas && this.isCrudCreateMode();
    }

    changeToEditMode() {
        if (this.isCrudViewMode() && !this.isNewBuildEditable()) {
            this.field('email').disable();
            this.field('mobile').disable();
            this.currentCrudMode = QuoteFormCrudMode.Edit;
            this.currentCrudModeBackUp = QuoteFormCrudMode.Edit;
        } else if (this.isCrudViewMode() && this.isNewBuildEditable()) {
            this.quoteForm.disable();
            this.field('nmi').enable();
            this.field('nmi').markAsTouched(); // Mark touched to trigger validation.
            this.currentCrudMode = QuoteFormCrudMode.Edit;
            this.currentCrudModeBackUp = QuoteFormCrudMode.Edit;
        }
        this.prefillActivityTableFromExistingQuote();
    }

    hasExternalErrors(): boolean {
        return this.externalSubmitErrors && this.externalSubmitErrors.length > 0;
    }

    cancelEditMode() {
        this.asyncConfig.isLoading = true;
        if (this.quoteFormModel) {
            this.quoteForm.reset(this.quoteFormModel);
        }
        this.hasErrorsOnSubmit = false;
        this.currentCrudMode = QuoteFormCrudMode.View;
        this.currentCrudModeBackUp = QuoteFormCrudMode.View;
        if (this.isNswScheme()) {
            this.asyncConfig.isLoading = false;
        }
        this.getSubsidy();
    }

    isConfirmable() {
        return (
            this.allDocumentsProvided &&
            this.quoteStatusService.isConfirmable(this.quoteStatus) &&
            this.quoteFormModel &&
            (this.quoteFormModel.nmi ||
                (this.isSaScheme() &&
                    this.quoteFormModel.alternateNetwork &&
                    this.quoteFormModel.meterIdentificationNumber))
        );
    }

    updateDocumentAvailability(documentAvailability: boolean) {
        this.allDocumentsProvided = documentAvailability;
    }

    isNewBuildEditable() {
        return (
            this.quoteFormModel && this.quoteFormModel.isNewBuild && this.quoteStatus === 'SubsidyConditionallyApproved'
        );
    }

    confirmInstallation() {
        this.onToConfirmInstallation.next({});
    }

    cancelQuote() {
        this.cancelDialog
            .open(ConfirmDialog, {
                data: {
                    title: 'Cancel Quote Record',
                    action: 'Are you sure you want to cancel this Quote Record?',
                    defaultNo: true
                }
            })
            .afterClosed()
            .subscribe(result => {
                if (result === this.confirmResponse) {
                    this.asyncConfig.isLoading = true;
                    this.quoteService.cancelQuote(this.quoteExternalId).subscribe(
                        cancelResult => {
                            this.onCancelled.next();
                        },
                        this.errorCallback.generate('cancelling Quote Record', this.snackBar, this.asyncConfig),
                        () => (this.asyncConfig.isLoading = false)
                    );
                }
            });
    }

    touchForm() {
        this.field('installationAddressIsPredominantResidential').markAsTouched();
        this.field('fairPricingConfirmation').markAsTouched();
        this.field('installationAddress').markAsTouched();
        this.field('batteryPackage').markAsTouched();

        const installationAddress = <FormGroup>this.field('installationAddress');
        for (const control of Object.keys(installationAddress.controls)) {
            installationAddress.get(control).markAsTouched();
        }

        Object.keys(this.quoteForm.controls).forEach(field => {
            const control = this.quoteForm.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    }

    onSubmit(model: QuoteFormModel) {
        this.externalSubmitErrors = [];
        this.hasErrorsOnSubmit = false;

        this.touchForm();

        if (this.quoteForm.valid) {
            this.onFormSubmitted.next({
                formModel: model,
                calculationModel: this.quoteCalculationModel,
                selectedPackages: this.selectedPackages,
                selectBuilder: this.selectedBuilder
            });
            if (this.isCrudEditMode()) {
                this.currentCrudMode = QuoteFormCrudMode.View;
                this.currentCrudModeBackUp = QuoteFormCrudMode.View;
            }
        } else {
            this.hasErrorsOnSubmit = true;
            this.submissionErrorSubject.next();
            this.navigate();
        }
    }

    navigate() {
        this.subNavigationService.signalNavigation(QuoteCreateStepper.Create);
    }

    loadApprovedBuilder(builderId: string) {
        if (!builderId) {
            return;
        }
        this.quoteService.getApprovedBuilder(builderId).subscribe(
            result => {
                this.selectedBuilder = result;
            },
            this.errorCallback.generate('getting approved builder', this.snackBar, this.asyncConfig),
            () => (this.asyncConfig.isLoading = false)
        );
    }

    getApprovedBuilders() {
        this.asyncConfig.isLoading = true;
        this.quoteService.getApprovedBuilders().subscribe(
            result => {
                this.approvedBuilders = result;
            },
            this.errorCallback.generate('getting approved builders', this.snackBar, this.asyncConfig),
            () => {
                this.asyncConfig.isLoading = false;
            }
        );
    }

    getSchemeCoverage() {
        this.asyncConfig.isLoading = true;
        this.quoteService.getSchemeCoverage(this.quoteScope).subscribe(
            result => {
                this.schemeCoverage.push(...result);
            },
            this.errorCallback.generate('getting eligible postcodes', this.snackBar, this.asyncConfig),
            () => {
                this.asyncConfig.isLoading = false;
            }
        );
    }

    getSubsidy() {
        if (!this.isSaScheme()) {
            return;
        }

        if (this.quoteExternalId && !this.isCrudEditMode()) {
            this.asyncConfig.isLoading = true;
            this.quoteService.getSubsidy(this.quoteExternalId).subscribe(
                result => {
                    this.asyncConfig.isLoading = false;
                    this.quoteCalculationModel.estimatedSubsidy = result;
                    this.handleQuoteAmountChange(result, 'estimatedSubsidy', false);
                },
                this.errorCallback.generate('finding subsidy amount', this.snackBar, this.asyncConfig),
                () => (this.asyncConfig.isLoading = false)
            );
        } else {
            this.getEstimatedSubsidy(this.field('batteryPackage').value);
        }
    }

    homeOwnershipFriendlyName(status: HomeOwnershipOrInterest) {
        return homeOwnershipOrInterestLabels[status];
    }

    _batteryCecSelected(pkg: CecPackageModel) {
        this.selectedPackages.battery = pkg;
    }

    _solarPanelSelected(pkg: CecPackageModel) {
        this.selectedPackages.solar = pkg;
    }

    _inverterPanelSelected(pkg: CecPackageModel) {
        this.selectedPackages.inverter = pkg;
    }

    packageTypeChange(model: PackageWithCategoryModel) {
        this.selectedPackages[model.category] = model.package;
        if (model.package && model.category === PackageCategory.Battery) {
            this.getEstimatedSubsidy(model.package.shortName);
        }
        this.onPackageChanged.next(model);
    }

    onEnergyConcessionRecipientChange($event) {
        this.getEstimatedSubsidy(this.field('batteryPackage').value);
    }

    getEstimatedSubsidy(packageType: string) {
        if (!packageType || !this.isSaScheme()) {
            return;
        }
        if (this.quoteStatusService.showLockedInSubsidyAmount(this.quoteStatus)) {
            return;
        }
        this.asyncConfig.isLoading = true;
        this.quoteService
            .calculateSubsidy(packageType, {
                scope: Scheme.SouthAustralia,
                isEnergyConcessionHolder: this.field('energyConcessionRecipient').value
            })
            .subscribe(
                result => {
                    this.quoteCalculationModel.estimatedSubsidy = result;
                    this.handleQuoteAmountChange(result, 'estimatedSubsidy', false);
                },
                this.errorCallback.generate('calculating subsidy', this.snackBar, this.asyncConfig),
                () => (this.asyncConfig.isLoading = false)
            );
    }

    panelInstallationTypeRequired() {
        return this.isNswScheme() && this.field('existingSolarPanels').value === true && this.hasSolarSystem();
    }

    showPropertyConsent() {
        return this.field('hasHomeOwnershipOrInterest').value === false;
    }

    hasExtraDeposit() {
        if (!this.quoteCalculationModel.balancePayableExcess) {
            return false;
        }
        return (
            this.field('noInterestBearingLoan').value ||
            this.quoteCalculationModel.balancePayableExcess < this.quoteCalculationModel.minimumLoanAmount
        );
    }

    newBuildSelected() {
        return this.field('isNewBuild').value === true;
    }

    openFeedInDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        this.feedInDisclaimerDialog.open(FeedInDisclaimerDialog);
    }

    openQuoteRenewalConfirmDialog(): void {
        this.quoteRenewalConfirmDialog.open(QuoteRenewalConfirmDialog, {
            data: {
                quoteExternalId: this.quoteExternalId,
                isMobile: this.isMobile,
                onRenewQuote: this.onRenewQuote
            }
        });
    }

    onRenewQuote = (newExpiryDate): void => {
        this.asyncConfig.isLoading = true;
        this.quoteService.renewQuote(this.quoteExternalId, newExpiryDate).subscribe(
            (result: QuoteRetrievedModel) => {
                this.quoteFormModel = result.quoteFormModel;
                this.quoteStatus = result.currentStatus;
                this.snackBar.open('Quote Record successfully renewed', 'Dismiss');
            },
            this.errorCallback.generate('renewing Quote Record', this.snackBar, this.asyncConfig),
            () => (this.asyncConfig.isLoading = false)
        );
    };

    ngOnDestroy() {
        if (this._mediaSubscription) {
            this._mediaSubscription.unsubscribe();
        }
    }

    isFeatureInstallerCanRenewQuoteRecordEnabled(): boolean {
        return environment.features.InstallerCanRenewQuoteRecord;
    }

    getValidationErrorMessageForViewMode() {
        if (this.quoteStatusService.isConfirmable(this.quoteStatus)) {
            if (
                this.allDocumentsProvided &&
                this.quoteFormModel &&
                this.quoteFormModel.isNewBuild &&
                !this.quoteFormModel.nmi
            ) {
                return 'Please provide NMI by editing this quote record to confirm installation';
            }
        }
        return '';
    }

    getInstallationTypeLabel(selectedInstallationType) {
        const filteredInstallationTypes = this.installationTypes.filter(it => it.value === selectedInstallationType);
        return filteredInstallationTypes.length > 0 ? filteredInstallationTypes[0].name : 'Unspecified';
    }

    hasSolarSystem = () => this.field('installationType').value.includes(InstallationType.Solar);
    hasBattery = () => this.field('installationType').value.includes(InstallationType.Battery);
    isBatteryAndSolarSystem = () => this.hasBattery() && this.hasSolarSystem();

    getSystemTypesExceptBatteryAndSolar = () => {
        return (this.field('installationType').value as string[]).filter(
            type => type !== InstallationType.Battery && type !== InstallationType.Solar
        );
    };

    hasSystem(sysType: string): boolean {
        return this.field('installationType').value.includes(sysType);
    }

    notSolarOrBattery(sysType: string): boolean {
        if (sysType === InstallationType.Battery || sysType === InstallationType.Solar) return false;
        return true;
    }

    getSystemPriceForViewMode(sysType: string): number {
        switch (sysType) {
            case InstallationType.HEMSDevice:
                return this.quoteFormModel.price_HEMSDevice;
            case InstallationType.HeatPumpWaterHeater:
                return this.quoteFormModel.price_HeatPumpWaterHeater;
            case InstallationType.EVCharger:
                return this.quoteFormModel.price_EVCharger;
            case InstallationType.InductionCooktop:
                return this.quoteFormModel.price_InductionCooktop;
            case InstallationType.SmartMeter:
                return this.quoteFormModel.price_SmartMeter;
            case InstallationType.SplitSystemAirConditioner:
                return this.quoteFormModel.price_SplitSystemAirConditioner;
            default:
                return 0;
        }
    }

    shouldDisplayPanelPrice = () => this.hasSolarSystem() || this.isInstallationTypeNotSet();

    getSystemModelIdentifierLabels(systemType: string): string {
        switch (systemType) {
            case InstallationType.HEMSDevice:
                return 'HEMS device';
            case InstallationType.HeatPumpWaterHeater:
                return 'Heat pump and hot heater';
            case InstallationType.EVCharger:
                return 'EV Charger';
            case InstallationType.InductionCooktop:
                return 'Induction cooktop';
            case InstallationType.SmartMeter:
                return 'Smart meter';
            case InstallationType.SplitSystemAirConditioner:
                return 'Split system air conditioner';
        }
        return '';
    }

    private prefillActivityTableFromExistingQuote() {
        if (this.field<FormArray>('activities').length > 0) return;

        this.quoteFormModel.activities.forEach(a => {
            this.field<FormArray>('activities').push(
                new FormGroup({
                    activityType: new FormControl(a.activityType),
                    associatedRebate: new FormControl(a.associatedRebate),
                    rebateAmount: new FormControl(Number(a.rebateAmount)),
                    vpp: new FormControl(a.vpp),
                    systemAndInstallationCost: new FormControl(a.systemAndInstallationCost),
                    additionalCost: new FormControl(a.additionalCost)
                })
            );
        });
    }

    getErrorMessages() {
        let errors = [];

        const addError = (controlName: string, errorType: string, message: string) => {
            const control = this.quoteForm.get(controlName);
            if (control && control.hasError(errorType) && control.touched) {
                errors.push(message);
            }
        };

        addError('firstName', 'required', 'Please enter a first name');
        addError('lastName', 'required', 'Please enter a last name');
        addError('email', 'required', 'Please enter an email address');
        addError('email', 'email', 'Please enter a valid email address');
        addError('mobile', 'required', 'Please enter a mobile number');
        addError('mobile', 'pattern', 'Please enter a valid mobile number');
        addError(
            'notApprovedForLowIncomeHouseholdsConfirmation',
            'required',
            'Please confirm that the applicant has not already been approved for a Solar for Low Income Households program'
        );
        addError(
            'householdIncomeConfirmation',
            'required',
            'Please confirm that you have informed the applicant that their combined household income must not exceed $180,000'
        );
        addError(
            'homeOwnershipOrInterestStatus',
            'required',
            "Please confirm the applicant's home ownership status for the installation property"
        );

        if (this.quoteForm.get('installationAddress').invalid && this.quoteForm.get('installationAddress').touched) {
            errors.push(
                this.quoteForm.get('installationAddress').hasError('required')
                    ? 'Please enter the installation address'
                    : 'Please enter a valid installation address'
            );
        }
        addError(
            'installationAddressIsPredominantResidential',
            'required',
            'Please confirm that the installation address is primarily residential'
        );
        addError('newBuildConfirmation', 'required', 'Please confirm that the property for installation is new build');
        addError('fairPricingConfirmation', 'required', 'Please confirm the fair pricing acknowledgement');
        addError('hasHomeOwnershipOrInterest', 'required', "Please select the customer's home ownership status");
        addError('nmi', 'required', 'Please enter the NMI');
        addError('nmi', 'pattern', 'Please enter a valid NMI');
        addError('meterIdentificationNumber', 'required', 'Please enter the Meter Identification Number');
        addError('meterIdentificationNumber', 'pattern', 'Please enter a valid Meter Identification Number');
        addError('vppOperator', 'required', 'Please enter the VPP operator');
        addError('intendedInstallationDate', 'required', 'Please set the proposed installation date');
        addError(
            'intendedInstallationDate',
            'matDatepickerMin',
            'Please select a proposed installation date that is not in the past'
        );

        addError('installationType', 'required', 'Please select the installation type');

        return errors;
    }
}
