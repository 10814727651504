import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
    selector: 'bp-exchange-auth',
    templateUrl: './exchange-auth.component.html',
    styleUrls: ['./exchange-auth.component.scss']
})
export class ExchangeAuthComponent implements OnInit {
    isLoading: boolean;
    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.isLoading = true;
        const fusionAuthConfig = environment.fusionAuth;
        const baseApplicationUrl = environment.baseAppUrl;

        const queryParams = this.route.snapshot.queryParams;
        const returnUrl = `${baseApplicationUrl}/${queryParams['returnUrl'] || 'dashboard'}`;

        window.location.replace(
            `${environment.membersUrl}/login/Auth?client_id=${fusionAuthConfig.clientId}&returnUrl=${returnUrl}`
        );
        console.log('Redirecting to external identity provider');
    }
}
