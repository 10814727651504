import { RenewableSystemType } from '@brokerportal/common/enums';

export interface SystemTypeOption {
    value: RenewableSystemType;
    label: string;
    icon: string; // Assuming MatIcon is used for icons
}

export const STANDARD_SYSTEM_TYPES: SystemTypeOption[] = [
    { value: RenewableSystemType.Solar, label: 'Solar panels', icon: 'wb_sunny' },
    { value: RenewableSystemType.Battery, label: 'Battery system', icon: 'battery_charging_full' },
    { value: RenewableSystemType.HeatPump, label: 'Heat pump', icon: 'local_fire_department' },
    { value: RenewableSystemType.SolarHotWater, label: 'Solar hot water', icon: 'shower' },
    { value: RenewableSystemType.EvCharger, label: 'EV charger', icon: 'electrical_services' }
];

export const HEUF_SYSTEM_TYPES: SystemTypeOption[] = [
    { value: RenewableSystemType.Aircon, label: 'Aircon', icon: 'ac_unit' },
    // { value: RenewableSystemType.SmartMeter, label: 'Smart Meter', icon: 'offline_bolt' },
    // { value: RenewableSystemType.PoolPump, label: 'Pool Pump', icon: 'pool' },
    {
        value: RenewableSystemType.EnergyMonitoringSystem,
        label: 'Energy Monitoring System',
        icon: 'monitor_heart'
    }
    // { value: RenewableSystemType.InductionCooktop, label: 'Induction Cooktop', icon: 'countertops' }
];
